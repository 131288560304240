import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { getHotArticle } from '@/services/japi';
import { setActiveNavId, setActiveNavKeys } from '@/store/config';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import { currentLocaleUrl, formatMts } from '@/utils';
import c from 'classnames';
import intl from 'react-intl-universal';
import styles from './index.module.less';
import { cloneDeep } from 'lodash';
import { defaultLocale } from '@/constants/mts';

interface MenuHoverProps {
    isIndex?: boolean;
    menus: any[];
    parentItem: any;
    childList: any[];
    onClickItem?: () => void;
}

const MenuHover: React.FC<MenuHoverProps> = ({ isIndex, menus, parentItem, childList = [], onClickItem }) => {
    const router = useRouter();
    const dispatch = useAppDispatch();
    const { topHeaderHeight: topHeight, activeNavKeys } = useAppSelector((state: any) => state.config);

    const currentLocale = intl.getInitOptions().currentLocale || defaultLocale;

    const [loading, setLoading] = useState<boolean>(true);
    // 是否登录
    const [isLogin, setIsLogin] = useState<boolean>(true);
    // 热门资讯
    const [hotList, setHotList] = useState<any[]>([]);
    // hover height
    const [hoverHeight, setHoverHeight] = useState<number>(0);

    useEffect(() => {
        setIsLogin(!!localStorage?.getItem('Authorization'));
    }, []);

    // 计算左侧宽度
    const leftWidth = useMemo(() => {
        const len = (childList || []).length;
        if (len < 4) {
            return 232;
        }
        return 438;
    }, [childList]);

    // 分割数组 3
    const spliceChildList = (l: any[], num: number) => {
        const newArr = cloneDeep(l); // 因为splice会改变原数组，要深拷贝一下
        const list = [];
        for (let i = 0; i < newArr.length; ) {
            list.push(newArr.splice(i, num));
        }
        return list;
    };

    // childList
    const newChildList = useMemo(() => {
        return spliceChildList(childList, 3);
    }, [childList]);

    useEffect(() => {
        setLoading(true);
        const { newsHotBlocksIds } = childList[0] || {};
        const normalHeight = isLogin ? 120 : 182;

        let caclLen = 0;
        newChildList.forEach((item, i: number) => {
            if (i % 2 === 0) {
                caclLen += item.length;
            }
        });

        setHoverHeight(normalHeight + caclLen * 56);

        if (newsHotBlocksIds) {
            const limitSize = caclLen + 1;
            getHotArticle({ limitSize, blockIds: newsHotBlocksIds.split(',') }, currentLocale)
                .then((res: any) => {
                    const { state, data = [] } = res || {};
                    if (state === 1) {
                        const dataLen = (data || []).length;

                        const newLen = isLogin ? (dataLen < limitSize ? limitSize : dataLen) : caclLen;
                        setHoverHeight(normalHeight + newLen * 56);
                        setHotList(data || []);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setHoverHeight(normalHeight + caclLen * 56);
            setLoading(false);
            setHotList([]);
        }
    }, [isLogin, childList, currentLocale, newChildList]);

    const toLogin = () => {
        (window as any)?.onLogin?.();
    };

    // 跳转
    const toKeyPath = useCallback((item: any, isIndex?: boolean) => {
        const { id, parentId, web, type, inventedUrl } = item;
        onClickItem?.();
        switch (type) {
            case 'page':
                dispatch(setActiveNavId(inventedUrl || id));
                const parentItem = menus.find((pItem: any) => pItem.id === parentId) || {};
                const pathKeys = [inventedUrl || id];
                if (parentItem?.id) {
                    pathKeys.unshift(parentItem?.inventedUrl || parentItem?.id);
                }
                dispatch(setActiveNavKeys(pathKeys));
                router.push(currentLocaleUrl(isIndex ? '/' : `/${pathKeys.join('/')}`));
                break;
            case 'web':
                const { url } = web;
                window.open(currentLocaleUrl(url));
                break;
            default:
                break;
        }
    }, []);

    /**
     * 渲染nav每一项
     */
    const navItemRender = useCallback((item: any, isIndex?: boolean) => {
        const { name, nameMts, children = [] } = item;
        const navMtsName = formatMts(nameMts) || name;

        return (
            <div
                className={styles.aHref}
                onClick={() => {
                    let activeItem = item;

                    if ((children || []).length !== 0) {
                        activeItem = (children || [])[0];
                    }
                    toKeyPath(activeItem, isIndex);
                }}
            >
                <span>{navMtsName}</span>
            </div>
        );
    }, []);

    // 根据二级导航重新计算topheight
    const newTopHeight = useMemo(() => {
        return activeNavKeys.length === 2 && topHeight > 60 ? topHeight - 60 : topHeight;
    }, [activeNavKeys, topHeight]);

    const parentNavMtsName = formatMts(parentItem?.nameMts) || parentItem?.name;

    return (
        <div
            className={c({ [styles.menu_hover]: true, [styles.hover_show]: childList.length !== 0 })}
            style={{
                top: childList.length !== 0 ? newTopHeight : newTopHeight - 2,
                height: childList.length !== 0 ? hoverHeight : 0,
            }}
            // className={c({ [styles.menu_hover]: true, [styles.hover_show]: true })}
            // style={{ top: newTopHeight, height: hoverHeight }}
        >
            <div className={c(styles.bg, styles.lf_bg)} />
            <div className={c(styles.bg, styles.rt_bg)} />

            <div className={styles.hover_wrapper}>
                <div className={c(styles.left_wrapper)} style={{ width: leftWidth }}>
                    {!isLogin && (
                        <div className={styles.go_login} style={{ maxWidth: leftWidth }} onClick={toLogin}>
                            <span>{intl.get('Header.LogToSeeAll').d('现在登录，畅想全部内容')}</span>
                            <span className={c('iconfont', 'icon-jiantou', styles.icon)} />
                        </div>
                    )}

                    <div className={styles.parent_name}>{childList.length !== 0 ? parentNavMtsName : null}</div>

                    <ul className={styles.child_list}>
                        {newChildList.map((cList: any[], i: number) => {
                            const fitem = cList[0];
                            return (
                                <li key={fitem.id}>
                                    {cList.map((item, j: number) => {
                                        const { id, name, nameMts, inventedUrl } = item;
                                        const navMtsName = formatMts(nameMts) || name;
                                        return (
                                            <div
                                                key={item.id}
                                                className={c(styles.nav_item, {
                                                    [styles.nav_active]:
                                                        activeNavKeys[1] &&
                                                        [inventedUrl, id].includes(activeNavKeys[1]),
                                                })}
                                                title={navMtsName}
                                            >
                                                {navItemRender(item, isIndex && i === 0 && j === 0)}
                                            </div>
                                        );
                                    })}
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className={c(styles.right_wrapper)} style={{ width: `calc(100% - ${leftWidth}px)` }}>
                    <div className={c(styles.recommend, { [styles.rec_nologin]: !isLogin })}>
                        {intl.get('Header.RecommendReading').d('推荐阅读')}
                    </div>

                    {hotList.length !== 0 ? (
                        <ul className={styles.rec_list}>
                            {hotList.map((item: any) => {
                                const { code, title, seo_url } = item;
                                return (
                                    <li key={code} title={title}>
                                        <a href={currentLocaleUrl(`/article/${seo_url || code}`)} target="_blank">
                                            <span className={c('iconfont', 'icon-row', styles.icon_row)} />
                                            <span className={styles.title}>{title}</span>
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : !loading ? (
                        <div className={styles.null_data} key="null">
                            {intl.get('Header.NoContentRecommend').d('暂无可推荐的内容')}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default MenuHover;
