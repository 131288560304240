import React from 'react';
import c from 'classnames';
import { NextImage } from '@/components';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import { useAppSelector } from '@/store/hook';

interface LoadMoreProp {
    page?: number;
    loading?: boolean;
    hasMore?: boolean;
    showNoMoreContent?: boolean;
    empty?: boolean;
    error?: boolean;
    emptyText?: string;
    action?: (a: number) => void;
    noDataHeight?: number;
    /** 自定义空状态展示 */
    noDataRender?: any;
}

const LoadMore: React.FC<LoadMoreProp> = (props) => {
    const {
        page,
        loading,
        hasMore,
        action,
        empty = false,
        showNoMoreContent = true,
        error = false,
        emptyText = intl.get('Common.NoData').d('暂无数据'),
        noDataHeight,
        noDataRender = null,
    } = props;

    const { showBarkly } = useAppSelector((state: any) => {
        return state.config;
    });

    if (error) {
        return (
            <div
                className={c('load-error', styles.error)}
                onClick={action ? () => action(page || 1) : () => console.log('no action...')}
            >
                {intl.get('Common.LoadFailedRetry').d('加载失败，点击重试')}
            </div>
        );
    } else if (empty) {
        if (noDataRender) {
            return noDataRender;
        }
        return (
            <div
                style={{ height: noDataHeight }}
                className={c('load-empty', styles.empty, { [styles.empty__barkly_container]: showBarkly })}
            >
                <div className={styles.empty__img}>
                    <NextImage layout="fill" src="/img/place/null_data_new.webp" alt="empty" />
                </div>
                <span>{emptyText}</span>
            </div>
        );
    } else if (loading) {
        return (
            <div className={c(styles.loadMore, styles.loadMoreLoading)}>
                <span>{intl.get('Common.Loading').d('加载中')}</span>
                <ol>
                    <li />
                    <li />
                    <li />
                </ol>
            </div>
        );
    } else if (hasMore) {
        return (
            <div
                className={c(styles.pcLoadMore, {
                    [styles.barkly_container]: showBarkly,
                })}
                onClick={() => {
                    action?.((page || 1) + 1);
                }}
            >
                {intl.get('Common.SeeMore').d('加载更多')}
            </div>
        );
    } else {
        if (!showNoMoreContent) {
            return null;
        }

        return (
            <div className={c('load-more', styles.loadMore, styles.loadMoreLoaded)}>
                <i />
                <span>{intl.get('Common.NoMoreContent').d('没有更多内容')}</span>
                <i />
            </div>
        );
    }
};

export default LoadMore;
