import React, { useRef, useState, useEffect, useMemo } from 'react';
import c from 'classnames';
import { resizeObserverFun, PAGE_CENTER_NEW } from '@/utils';
import { FirstAdvertise } from '@/components/public/CustomAdvertise';
import { setSlideAd } from '@/store/config';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import type { MainProps } from '../types';
import styles from './index.module.less';
import { cloneDeep } from 'lodash';

const Main: React.FC<MainProps> = (parmas) => {
    // isNew => 新的布局 1376       （1184）
    const { child, showAdvertising = false, leftAdvertisingCode, rightAdvertisingCode, pageData } = parmas || {};

    // 侧边广告高度
    const adHeight = 686;
    const minWidth = 32;
    const dispatch = useAppDispatch();
    const { topHeaderHeight: topHeight, activeNavKeys } = useAppSelector((state: any) => state.config);

    // 中心区域宽度
    const mainRef = useRef<any>(null);
    const [mainWidth, setMainWidth] = useState<number>(0);
    useEffect(() => {
        resizeObserverFun(mainRef.current, setMainWidth);
    }, []);

    // 超版心或视频轮播图  elementId
    const [swiperElementIdId, setSwiperElementIdId] = useState<string>('');
    const [swiperOffsetTop, setSwiperOffsetTop] = useState<number>(0);

    // 侧边宽度
    const sideWidth = useMemo(() => {
        return (mainWidth - PAGE_CENTER_NEW) / 2;
    }, [mainWidth]);

    // 根据二级导航重新计算topheight
    const newTopHeight = useMemo(() => {
        return activeNavKeys.length === 2 && topHeight > 60 ? topHeight - 60 : topHeight;
    }, [activeNavKeys, topHeight]);

    // 获取超版心或视频轮播图  elementId
    useEffect(() => {
        const { children = [] } = pageData || {};
        const cloneList = cloneDeep(children || []).reverse();

        const swiperItem = cloneList.find((child: any) => {
            const { id, props } = child || {};
            return id === 'SM_New_Visual_Swiper' && ['3', '4'].includes(props?.styleType);
        });

        const { elementId } = swiperItem || {};
        if (elementId) {
            setSwiperElementIdId(elementId || '');
            dispatch(
                setSlideAd({
                    elementId,
                    showAdvertising,
                    leftAdvertisingCode,
                    rightAdvertisingCode,
                }),
            );
        } else {
            setSwiperElementIdId(elementId || '');
            dispatch(
                setSlideAd({
                    showAdvertising: false,
                }),
            );
        }
    }, [dispatch, pageData]);

    return (
        <main className={c({ [styles.main]: true })} ref={mainRef}>
            {showAdvertising && leftAdvertisingCode && sideWidth > minWidth && !swiperElementIdId && (
                <aside className={c(styles.aside, styles.left_aside)} style={{ width: sideWidth, top: newTopHeight }}>
                    <FirstAdvertise
                        elementId="leftAdvertisingCode"
                        adCode={leftAdvertisingCode}
                        moreStyle={{ width: sideWidth, height: adHeight }}
                    />
                </aside>
            )}

            <section className={styles.section}>{child}</section>

            {showAdvertising && rightAdvertisingCode && sideWidth > minWidth && !swiperElementIdId && (
                <aside className={c(styles.aside, styles.right_aside)} style={{ width: sideWidth, top: newTopHeight }}>
                    <FirstAdvertise
                        elementId="rightAdvertisingCode"
                        adCode={rightAdvertisingCode}
                        moreStyle={{ width: sideWidth, height: adHeight }}
                    />
                </aside>
            )}
        </main>
    );
};

export default Main;
