/** 不在头部展示面包屑的名单 */
export const noShowHead = ['/broker/[brokerId]'];

/**
 * 面包屑页面等级
 * 防止路径重叠,使用router的pathname记录
 */
export const breadcrumbsHierarchyMap = [
    // ------------------ 首页 ------------------
    [
        {
            url: '/',
            name: '首页',
            mts: 'Crumbs.HomePage',
        },
    ],
    // ------------------ 1级页面 ------------------
    [
        // 一级导航
        {
            url: '/[firstId]',
            name: '一级导航',
            mts: 'Crumbs.PrimaryNavigation',
        },
        // 下载APP落地页
        {
            url: '/app',
            name: 'APP下载',
            mts: 'Crumbs.APPDownload',
        },
        // 举报投诉页
        {
            url: '/feedback',
            name: '举报投诉',
            mts: 'Crumbs.ReportComplaint',
        },
        //  ------ 个人中心相关页面 ------
        {
            url: '/message', // 消息中心
            name: '消息中心',
            mts: 'Message.MessageCenter',
        },
        {
            url: '/center', // 个人中心
            name: '个人中心',
            mts: 'Header.PersonalCenter',
        },
        {
            url: '/setting', // 账号设置
            name: '账号设置',
            mts: 'Setting.AccountSettings',
        },
    ],
    // ------------------ 2级页面 ------------------
    [
        // 二级导航
        {
            url: '/[firstId]/[secondId]',
            name: '二级导航',
            mts: 'Crumbs.SecondaryNavigation',
        },
    ],

    // ------------------ 3级页面 ------------------
    [
        // 交易商榜单详情
        {
            url: '/broker/list/detail/[listId]',
            name: '交易商榜单详情',
            mts: 'Crumbs.BrokerListDetails',
        },
        // 全部专栏列表
        {
            url: '/topic/list',
            name: '全部专栏',
            mts: 'Crumbs.AllColumns',
        },
        // 全部创作者列表
        {
            url: '/ugc',
            name: '全部创作者',
            mts: 'Crumbs.AllCreators',
        },
        // 搜索结果页
        {
            url: '/search',
            name: '搜索结果',
            mts: 'Crumbs.SearchResults',
        },
        // 关键词详情页
        {
            url: '/keyword',
            name: '关键词详情',
            mts: 'Crumbs.KeywordDetails',
        },
        // 交易工具
        {
            url: '/broker/tradeTool',
            name: '交易工具',
            mts: 'Broker.TradeToolBannerText',
        },
        // 联系我们\关于我们\媒体矩阵
        {
            url: '/contactUS',
            name: '联系我们',
            mts: 'Header.ContactUs',
        },
    ],
    // ------------------ 4级页面 ------------------
    [
        // 交易商详情页
        {
            url: '/broker/[brokerId]',
            name: '交易商详情',
            mts: 'Crumbs.BrokerDetails',
        },
        // 活动详情页
        {
            url: '/activity/[activityId]',
            name: '活动详情',
            mts: 'Crumbs.ActivityDetails',
        },
        // 专栏详情页
        {
            url: '/topic/[topicId]',
            name: '专栏详情',
            mts: 'Crumbs.TopicDetails',
        },
        // 创作者详情页
        {
            url: '/ugc/[ugcId]',
            name: '创作者详情',
            mts: 'Crumbs.CreatorDetails',
        },
        // 交易工具详情页
        {
            url: '/broker/tradeTool/[toolId]',
            name: '交易工具详情',
            mts: 'Crumbs.TradingInstrumentDetails',
        },
    ],
    // ------------------ 5级页面 ------------------
    [
        // 合集稿件聚合页
        {
            url: '/block/[blockId]',
            name: '全部资讯',
            mts: 'Crumbs.AllInformation',
        },
        // 未绑定导航的可视化展示页面
        {
            url: '/page/[pageId]',
            name: '展示页面',
            mts: 'Crumbs.DisplayPage',
        },
        // 用户点评列表
        {
            url: '/broker/reputation',
            name: '用户点评',
            mts: 'Broker.UserComments',
        },
        // 常见问题列表
        {
            url: '/faq',
            name: '常见问题',
            mts: 'Broker.FAQNavs',
        },
    ],
    // ------------------ 6级页面 ------------------
    [
        // 文章详情页/视频详情页/快讯详情页/常见问题详情
        {
            url: '/article/[...param]',
            name: '文章详情',
            mts: 'Crumbs.ArticleDetails',
        },
        // 用户点评详情
        {
            url: '/broker/reputation/[id]',
            name: '点评详情',
            mts: 'Crumbs.ReviewDetails',
        },
        // 发布用户点评
        {
            url: '/broker/complaint',
            name: '发布点评',
            mts: 'Crumbs.PostReview',
        },
    ],
    // ------------------ 7级页面 ------------------
    [
        // 举报用户点评
        {
            url: '/broker/report',
            name: '举报点评',
            mts: 'Crumbs.ReportReview',
        },
    ],
];

/** 稿件面包屑名称mts */
export const ArticleNameMtsMap: Record<string, string> = {
    common: 'Crumbs.ArticleDetails',
    video: 'Crumbs.VideoDetails',
    audio: 'Crumbs.AudioDetails',
    quick: 'Crumbs.NewsDetails',
};
