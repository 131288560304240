import React, { memo, useCallback, useState, useEffect } from 'react';
import styles from './index.module.less';
import c from 'classnames';
import StarLv from './StarLv';
import Information from './components/Information';
import { NextImage, BrokerBusinessTag } from '../index';
import intl from 'react-intl-universal';
import { currentLocaleUrl } from '@/utils';

interface Props {
    /** 列表数据 */
    list: any[];
    /** 外层classname */
    wrapperClassname?: string;
    /** 子元素classname */
    itemClassname?: string;
    /** 搜索关键词 */
    keyword?: string;
    /** 是否展示排名 */
    isRanking?: boolean;
    /** 类型 */
    type?: 'all' | 'search' | 'list';
    /** 对比 */
    onCompared?: (item: any) => void;
}

const iconMap = ['one', 'two', 'three'];

const BrokerList: React.FC<Props> = ({
    list = [],
    wrapperClassname = '',
    itemClassname = '',
    isRanking = false,
    keyword = '',
    type = '',
    onCompared,
}) => {
    const [origin, setOrigin] = useState<string>('');

    useEffect(() => {
        setOrigin(window?.location?.origin || '');
    }, []);

    // 搜索替换
    const replaceTitle = (name: string, val: string) => {
        const regex = new RegExp(`(${val.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');
        return (name || '').replace(regex, (_, p1) => {
            return `<span>${p1}</span>`;
        });
    };

    // 名称处理
    const formatTitle = useCallback(
        (name: string) => {
            return keyword ? replaceTitle(name, keyword) : name;
        },
        [keyword],
    );

    return (
        <div className={wrapperClassname}>
            {list.map((item: any, index: number) => {
                const {
                    id,
                    age,
                    logo,
                    rate,
                    score,
                    full_name,
                    name_abbreviation,
                    business_name,
                    license,
                    seo_url,
                    broker_describe = {},
                } = item;

                return (
                    <div key={id} className={itemClassname}>
                        <a
                            href={currentLocaleUrl(`/broker/${seo_url || id}`)}
                            target="_blank"
                            className={c(
                                styles.wrapper,
                                { [styles.search__list]: keyword },
                                { [styles.all__wrapper]: type === 'all' },
                            )}
                        >
                            {index < 3 && isRanking && (
                                <em className={styles.icon}>
                                    <NextImage
                                        src={`/broker/icon/ranking-${index + 1}.webp`}
                                        needPrefix
                                        alt="rank"
                                        width={24}
                                        height={29}
                                    />
                                </em>
                            )}
                            <div className={styles.left__wrapper}>
                                <NextImage src={logo} layout="fill" alt={name_abbreviation} place="logo_b" />
                                {type === 'all' && <BrokerBusinessTag name={business_name} site="leftTop" />}
                            </div>
                            <div className={styles.main__wrapper}>
                                <div
                                    className={styles.title}
                                    dangerouslySetInnerHTML={{ __html: formatTitle(name_abbreviation) }}
                                />
                                <div className={styles.content__wrapper}>
                                    <p
                                        className={styles.fullName}
                                        dangerouslySetInnerHTML={{ __html: formatTitle(full_name) }}
                                    />
                                    <p className={styles.supervise}>{license?.join?.(' | ') || ''}</p>
                                    <div className={styles.mobile__score}>
                                        <span>{intl.get('Search.Score').d('评分：')}</span>
                                        <span className={styles.score}>{score?.toFixed?.(1)}</span>
                                        <div>{type === 'all' && <StarLv grade={rate} />}</div>
                                    </div>
                                    {type !== 'all' && (
                                        <div className={styles.mobile__star}>
                                            <StarLv grade={rate} />
                                        </div>
                                    )}
                                </div>
                                {type === 'all' && (
                                    <div className={c(styles.contrast__wrapper, styles.mobile)}>
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.open(
                                                    currentLocaleUrl(`/page/Pc_Barterer_Comparison?ids=${encodeURIComponent(id)}`),
                                                );
                                                // onCompared?.(item);
                                            }}
                                            className={styles.contrast}
                                        >
                                            {intl.get('Search.Compared').d('对比')}
                                        </div>
                                    </div>
                                )}
                                <div className={styles.tags__list}>
                                    {age && <span className={styles.tags__item}>{age}</span>}
                                    {type !== 'all' && (
                                        <span className={styles.tags__mobile}>
                                            {age}
                                            {age && license.length > 0 ? ' | ' : ''}
                                            {license?.join?.(' | ') || ''}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className={styles.right__wrapper}>
                                <div className={styles.grade__text}>{score?.toFixed?.(1)}</div>
                                <div className={styles.grade__star}>
                                    <StarLv grade={rate} />
                                </div>
                            </div>
                            {type === 'search' && <BrokerBusinessTag name={business_name} site="rightTop" />}
                            {type === 'list' && (
                                <BrokerBusinessTag
                                    className={styles.businessTag}
                                    name={business_name}
                                    site="rightTop"
                                />
                            )}
                        </a>
                        {type === 'all' && (
                            <div className={c(styles.contrast__wrapper, styles.contrast__wrapper_pc)}>
                                <div
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onCompared?.(item);
                                    }}
                                    className={styles.contrast}
                                >
                                    {intl.get('Search.Compared').d('对比')}
                                </div>
                            </div>
                        )}
                        {type === 'all' && <Information dataSource={broker_describe} />}
                    </div>
                );
            })}
        </div>
    );
};

export default memo(BrokerList);
