/** 重点页 */
export const highlightPage = [
    {
        mtsKey: 'Header.ContactUs',
        label: '联系我们',
        link: '/contactUS',
    },
    {
        mtsKey: 'Header.Creator',
        label: '创作者',
        link: '/ugc',
    },
    {
        mtsKey: 'Header.DownloadApp',
        label: '下载App',
        link: '/app',
    },
    {
        mtsKey: 'Broker.TradeToolBannerText',
        label: '交易工具',
        link: '/broker/tradeTool',
    },
];
