import React, { useEffect, useState, useMemo } from 'react';
import type { ReactNode } from 'react';

import { PAGE_MOBILE_W, resizeObserverFun } from '@/utils';

interface IProps {
    m: ReactNode;
    ipad?: ReactNode;
    pc: ReactNode;
}
const TransferCom: React.FC<IProps> = (props) => {
    const { m, ipad, pc } = props;

    // 屏幕宽度
    const [winWidth, setWinWidth] = useState<number>(1024);

    useEffect(() => {
        resizeObserverFun(document.querySelector('body'), setWinWidth);
    }, []);

    const render = useMemo(() => {
        if (winWidth < PAGE_MOBILE_W && m) {
            return m;
        } else if (winWidth >= PAGE_MOBILE_W && winWidth <= 1024 && ipad) {
            return ipad;
        } else {
            return pc;
        }
    }, [ipad, m, pc, winWidth]);

    return <>{render}</>;
};
export default TransferCom;
