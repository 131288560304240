/** 系统创建页面名称 */
export const systemPageNameMap: Record<string, { mts: string; name: string }> = {
    // 	全部交易商
    Pc_Broker_All: {
        mts: 'Page.BrokerAll',
        name: '全部交易商',
    },
    // 交易商榜单
    Pc_Broker_Billboard: {
        mts: 'Page.BrokerBillboard',
        name: '交易商榜单',
    },
    // 交易商对比
    Pc_Barterer_Comparison: {
        mts: 'Page.BartererComparison',
        name: '交易商对比',
    },
};
