import React, { useMemo } from 'react';

import { NextImage } from '@/components';
import intl from 'react-intl-universal';
import { formatBrokerBusiness } from '@/utils';
import type { BrokerItemProps } from '../../type';
import styles from './index.module.less';

const BrokerItem: React.FC<BrokerItemProps> = ({ index, record, locale, onDeleteItem }) => {
    const currentLocale = locale || intl.getInitOptions().currentLocale;
    const { logo, no_scale_logo, name_abbreviation, age, score, business_name, broker_datum, broker_other_info } =
        record;

    /** 删除按钮宽度 */
    const delBtnWidth = useMemo(() => {
        if (currentLocale === 'en') {
            return 56;
        }

        return 48;
    }, [currentLocale]);

    /** 牌照信息 */
    const licenseList = useMemo(() => {
        const list = broker_datum?.supervisory_licence || broker_other_info?.data_upload?.supervisory_licence || [];
        return list.map((item: any) => item.name);
    }, []);

    return (
        <div className={styles.li}>
            <div className={styles.top}>
                <div className={styles.logo_box}>
                    <div className={styles.logo}>
                        <NextImage
                            src={no_scale_logo || logo}
                            layout="fill"
                            alt={name_abbreviation}
                            place="new_normal"
                        />
                    </div>
                </div>

                <div className={styles.info}>
                    <div className={styles.info_top}>
                        <p className={styles.name}>{name_abbreviation}</p>
                        <p className={styles.score}>
                            <span>
                                <strong>{Number(score || 0).toFixed(1)}</strong>
                            </span>
                            <span>{intl.get('Broker.Score').d('评分')}</span>
                        </p>
                    </div>

                    <div className={styles.tags}>
                        <span>{formatBrokerBusiness(business_name, currentLocale)}</span>
                        <span>{age}</span>
                    </div>
                </div>
            </div>

            <div className={styles.bot_list}>
                <span className={styles.license} style={{ width: `calc(100% - ${delBtnWidth}px)` }}>
                    {licenseList?.join?.('  |  ') || ''}
                </span>

                <span
                    className={styles.del}
                    onClick={(event: any) => {
                        event?.preventDefault();
                        event?.stopPropagation();
                        event?.nativeEvent.stopImmediatePropagation();
                        onDeleteItem?.({ ...record, id: record?.id || record?._id }, index || 0);
                    }}
                >
                    {intl.get('Common.Delete').d('删除')}
                </span>
            </div>
        </div>
    );
};

export default BrokerItem;
