import React, { useRef, useState } from 'react';
import { Popover } from 'antd';
import c from 'classnames';
import { useRouter } from 'next/router';

import { DownOutlined, GlobalOutlined } from '@ant-design/icons';
import { defaultLocale, langItems } from '@/constants/mts';

import styles from './index.module.less';
import type { MtsLangProps } from '../types';

const MtsLang: React.FC<MtsLangProps> = (props) => {
    const { locale } = props;
    const router = useRouter();
    const langContent = useRef<any>(null);

    // 选择项
    const [selectedObjs, setSelectedObjs] = useState<Record<string, string>>({
        key: locale || defaultLocale,
        label:
            langItems.find((item: Record<string, string>) => item.key === (locale || defaultLocale))?.label ||
            '简体中文',
    });

    const [open, setOpen] = useState<boolean>(false);

    // pc 语言
    const handleButtonClick = (item: any) => {
        router.replace(router.asPath, router.asPath, { locale: item.key });
        setSelectedObjs({ ...item });
        setOpen(false);
    };

    return (
        <Popover
            open={open}
            // arrowPointAtCenter
            placement="bottomRight"
            overlayClassName={styles.overlay}
            getPopupContainer={() => langContent.current}
            onOpenChange={(e) => setOpen(e)}
            content={langItems.map((item) => (
                <div
                    key={item.key}
                    className={c(styles.p, item?.key === selectedObjs.key && styles.active)}
                    onClick={() => handleButtonClick(item)}
                >
                    {item.label}
                </div>
            ))}
        >
            <div className={styles.content} ref={langContent}>
                <GlobalOutlined className={styles.prefix} />
                <span className={styles.text}>{selectedObjs.label}</span>
                <DownOutlined className={styles.suffix} />
            </div>
        </Popover>
    );
};

export default MtsLang;
