import React, { useState, useRef, useEffect, useMemo } from 'react';
import { resizeObserverFun } from '@/utils';
import { NextImage } from '@/components';
import intl from 'react-intl-universal';
import { formatBrokerBusiness } from '@/utils';
import type { BrokerItemProps } from '../../type';
import styles from './index.module.less';

const BrokerItem: React.FC<BrokerItemProps> = ({ index, record, locale, onDeleteItem }) => {
    const currentLocale = locale || intl.getInitOptions().currentLocale;

    const [tagWidth, setTagWidth] = useState<number>(300); // tag width
    const tagRef = useRef<any>(null);
    useEffect(() => {
        // 获取tag宽度
        resizeObserverFun(tagRef.current, setTagWidth);
    }, []);

    const { logo, no_scale_logo, name_abbreviation, age, score, business_name, broker_datum, broker_other_info } =
        record;

    /** 删除按钮宽度 */
    const delBtnWidth = useMemo(() => {
        if (currentLocale === 'en') {
            return 78;
        }

        return 68;
    }, [currentLocale]);

    /** 牌照信息 */
    const licenseList = useMemo(() => {
        const list = broker_datum?.supervisory_licence || broker_other_info?.data_upload?.supervisory_licence || [];
        return list.map((item: any) => item.name);
    }, []);

    return (
        <div className={styles.li}>
            <div className={styles.logo_box}>
                <div className={styles.logo}>
                    <NextImage src={no_scale_logo || logo} layout="fill" alt={name_abbreviation} place="new_normal" />
                </div>
            </div>
            <div className={styles.info}>
                <div className={styles.info_top}>
                    <p className={styles.name}>{name_abbreviation}</p>
                    <p className={styles.score}>
                        <span>
                            <strong>{Number(score || 0).toFixed(1)}</strong>
                        </span>
                        <span>{intl.get('Broker.Score').d('评分')}</span>
                    </p>
                </div>

                <div className={styles.tag_list}>
                    <span className={styles.tags} ref={tagRef}>
                        <span>{formatBrokerBusiness(business_name, currentLocale)}</span>
                        <span>{age}</span>
                    </span>

                    <span className={styles.license} style={{ width: `calc(100% - ${tagWidth + delBtnWidth}px)` }}>
                        {licenseList?.join?.('  |  ') || ''}
                    </span>

                    <span
                        className={styles.del}
                        onClick={(event: any) => {
                            event?.preventDefault();
                            event?.stopPropagation();
                            event?.nativeEvent.stopImmediatePropagation();
                            onDeleteItem?.({ ...record, id: record?.id || record?._id }, index || 0);
                        }}
                    >
                        {intl.get('Common.Delete').d('删除')}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default BrokerItem;
