import React from 'react';
import { Popover } from 'antd';
import c from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import intl from 'react-intl-universal';
import { NextImage } from '@/components';
import { Cookies } from 'react-cookie';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import { resetUserInfo } from '@/store/config';
import styles from './index.module.less';

interface WhatsAPPProps {
    locale?: string;
}

const LoginBtn: React.FC<WhatsAPPProps> = ({ locale }) => {
    const router = useRouter();
    const dispatch = useAppDispatch();

    const { userInfo, unreadNum } = useAppSelector((state: any) => state.config);

    const { icon, nickname } = userInfo || {};

    const extendsPlans = [
        {
            name: intl.get('Header.PersonalCenter').d('个人中心'),
            icon: 'icon-user',
            href: '/center',
        },
        {
            name: intl.get('Message.MessageCenter').d('消息中心'),
            icon: 'icon-msg',
            href: '/message',
        },
        {
            name: intl.get('Header.AccountSet').d('账户设置'),
            icon: 'icon-setting',
            href: '/setting',
        },
    ];

    /**
     * 判断字节长度
     * @param str
     * @param num
     * @returns
     */
    const formatNickName = (str: string, num: number = 6) => {
        // 获取字符串的字节数
        let count = 0; // 初始化字节数递加变量并获取字符串参数的字符个数
        let temp = false;
        const nameList: any = [];

        if (str) {
            // 如果存在字符串，则执行
            const strList = str.split('');
            const newList = strList.map((value: any) => {
                return { value, len: value.charCodeAt(0) > 255 ? 2 : 1 };
            });
            newList.forEach((item: any) => {
                count += item.len;
                if (count <= num) {
                    nameList.push(item.value);
                } else {
                    if (!temp) {
                        temp = true;
                        nameList.push('...');
                    }
                }
            });
            return nameList.join(''); // 返回字节数
        } else {
            return ''; // 如果参数为空，则返回0个
        }
    };

    /**
     * 登录
     */
    const loginIn = () => {
        (window as any)?.onLogin?.();
    };

    /**
     * 退出登录
     */
    const logUp = () => {
        router.reload();
        const cookies = new Cookies();
        cookies?.remove('AUTH_UID');
        cookies?.remove('AUTH_TOKEN', { path: '/' });
        localStorage.clear();
        dispatch(resetUserInfo());

        (window as any).Tawk_API?.logout?.(function (error: any) {
            console.log('tawk error: ', error);
        });
    };

    return (
        <>
            {nickname ? (
                <Popover
                    overlayClassName={c({ [styles.ant_popover]: true, [styles.ant_popover_en]: locale === 'en' })}
                    placement="bottom"
                    content={
                        <div className={styles.extends_plan}>
                            {extendsPlans.map((item) => {
                                const { name, icon, href } = item;
                                return (
                                    <Link href={href} key={href}>
                                        <span className={styles.extends_plan_item}>
                                            <span className={styles.extends_plan_left}>
                                                <span className={c(styles.user, 'iconfont', icon)} />
                                                <span>{name}</span>
                                            </span>
                                            <NextImage
                                                src="/center/arrow.webp"
                                                needPrefix
                                                width={14}
                                                height={14}
                                                alt="arrow"
                                            />
                                        </span>
                                    </Link>
                                );
                            })}

                            <div onClick={logUp} className={styles.log_up}>
                                {intl.get('Header.SignOut').d('退出登录')}
                            </div>
                        </div>
                    }
                >
                    <div className={styles.avatar}>
                        <NextImage src={icon} width={24} height={24} alt="user" place="user" />
                        <span>{formatNickName(nickname)}</span>
                    </div>
                </Popover>
            ) : (
                <div
                    onClick={loginIn}
                    aria-label="Login in or up"
                    className={c({ [styles.login_btn]: true, [styles.en_reg_log_btn]: locale === 'en' })}
                >
                    {intl.get('Login.LoginInOrUp').d('登录/注册')}
                </div>
            )}
        </>
    );
};

export default LoginBtn;
