import { useEffect, useRef } from 'react';

import CustomVideos from '@/utils/video';

interface Props {
    id?: string;
    dependency?: any[];
}

function useVideoPlayers(props?: Props) {
    const { id = '#video-warpper', dependency = [] } = props || {};
    const players = useRef<any>(null); // 视频类

    // 视频处理
    useEffect(() => {
        const width = '100%';
        const height = '100%';
        players.current = new CustomVideos({
            selector: id,
            width,
            height,
        });

        return () => players.current?.dispose?.();
    }, dependency);

    return { players };
}

export default useVideoPlayers;
