import React, { useEffect, useMemo, useState } from 'react';
import { NextImage } from '@/components';
import {
    WeiboShareButton,
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
} from 'next-share';

type TypeType = 'weibo' | 'facebook' | 'twitter' | 'telegram' | 'whatsapp';

interface Props {
    /** 类型 */
    type: TypeType;
    /** 样式 */
    style?: any;
    /** 图标大小 */
    size?: number;
    /** 标题 */
    title?: string;
    /** 标题 */
    /** 地址 */
    url?: any;
    /** 封面 */
    cover?: string;
    /** 布局场景 common-通用 video-视频 */
    layout?: 'common' | 'video';
}

const Share: React.FC<Props> = ({ size, title, url, cover, type, style = {}, layout = 'common' }) => {
    const [shareUrl, setShareUrl] = useState<string>('');
    const [isCheck, setIsCheck] = useState<boolean>(false);

    /** 获取当前链接 */
    useEffect(() => {
        setShareUrl(url || location.href);
    }, [url]);

    /** 分享按钮 */
    const ShareButton = useMemo(() => {
        switch (type) {
            case 'weibo':
                return WeiboShareButton;
            case 'facebook':
                return FacebookShareButton;
            case 'twitter':
                return TwitterShareButton;
            case 'telegram':
                return TelegramShareButton;
            case 'whatsapp':
                return WhatsappShareButton;
            default:
                return React.Fragment;
        }
    }, [type]);

    /** 图片默认/选中链接 */
    const { imgSrc, checkImgSrc } = useMemo(() => {
        if (layout === 'common') {
            return {
                imgSrc: `/bind/${type}.webp`,
                checkImgSrc: `/bind/${type}_check.webp`,
            };
        }
        return {
            imgSrc: `/bind/${layout}_${type}.webp`,
            checkImgSrc: `/bind/${layout}_${type}_check.webp`,
        };
    }, [type, isCheck, layout]);

    return (
        <ShareButton url={shareUrl} title={title} quote={title} style={{ width: size, height: size, ...style }}>
            {/* 写两份,防止切换状态导致的重复请求图片,衍生出请求高频导致的图片请求停止的问题 */}
            <div
                onMouseEnter={() => {
                    setIsCheck(true);
                }}
                onMouseLeave={() => {
                    setIsCheck(false);
                }}
                style={{ display: isCheck ? 'none' : 'block' }}
            >
                <NextImage needPrefix src={imgSrc} width={size} height={size} alt={type} />
            </div>
            <div
                onMouseEnter={() => {
                    setIsCheck(true);
                }}
                onMouseLeave={() => {
                    setIsCheck(false);
                }}
                style={{ display: !isCheck ? 'none' : 'block' }}
            >
                <NextImage needPrefix src={checkImgSrc} width={size} height={size} alt={type} />
            </div>
        </ShareButton>
    );
};

export default Share;
