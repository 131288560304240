import { Modal } from 'antd';
import React, { useState } from 'react';

import { NextImage } from '@/components';
import { useAppSelector } from '@/store/hook';

import { Login } from '../../public';
import styles from './index.module.less';
import type { LoginFormType } from '../../public/Login/content';

function Index() {
    const { imgPrefix } = useAppSelector((state: any) => state.config);

    const [modalOpen, setModalOpen] = useState(false);
    const [type, setType] = useState<LoginFormType>();
    const [isClose, setIsClose] = useState<boolean>(true); // 是否允许关闭弹窗

    /**
     * 打开弹窗
     */
    const onLogin = (type?: LoginFormType, close?: boolean) => {
        setModalOpen(true);

        /** 设置流程 */
        if (type) {
            setType(type);
        }

        /** 不允许手动关闭页面 */
        if (close) {
            setIsClose(false);
        }
    };

    /**
     * 关闭弹窗
     */
    const onCancel = () => {
        if (!isClose) {
            return;
        }
        setModalOpen(false);
    };

    const LoginModal = (
        <Modal
            width={460}
            footer={null}
            open={modalOpen}
            onCancel={onCancel}
            wrapClassName={isClose ? '' : styles.wrapper}
            destroyOnClose
            className={styles.edit_modal}
            centered
            maskClosable={false}
            keyboard={isClose}
            closeIcon={
                isClose ? (
                    <NextImage src={`/setting/modal_close.webp`} needPrefix width={20} height={20} alt="close" />
                ) : (
                    false
                )
            }
            style={{ backgroundImage: `url('${imgPrefix}/setting/modal_bg.webp')` }}
        >
            <Login type={type} onCancel={onCancel} />
        </Modal>
    );

    return {
        /** 登录 */
        onLogin,
        /** 弹窗内容 */
        LoginModal,
    };
}

export default Index;
