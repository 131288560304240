export const menuExtraList = [
    {
        name: 'Header.ContactUs',
        alias: '联系我们',
        link: '/contactUS',
    },
    {
        name: 'Header.Creator',
        alias: '创作者',
        link: '/ugc',
    },
    {
        name: 'Header.DownloadApp',
        alias: '下载app',
        link: '/app',
    },
    {
        name: 'Broker.TradeToolBannerText',
        alias: '交易工具',
        link: '/broker/tradeTool',
    },
];
