import { useEffect, useMemo, useState } from 'react';
import { message } from 'antd';
import intl from 'react-intl-universal';

import { apiLangHeaderMap, defaultLocale } from '@/constants/mts';
import { tawkInfoObj } from '@/reconstruction/config/tawk';
import { useAppSelector } from '@/store/hook';

interface Props {
    tawk_switch: boolean;
    lang: string;
}

/** Tawk客服 */
function useTawk(props: Props) {
    const { lang, tawk_switch } = props;

    const { userInfo } = useAppSelector((state: any) => state.config);

    // 是否展示客服
    const [showTawk, setShowTawk] = useState<boolean>(false);

    const tawkInfo = useMemo(() => {
        const langKey = apiLangHeaderMap[lang || defaultLocale];

        return tawkInfoObj[langKey] || tawkInfoObj.zh;
    }, [lang]);

    // 监听tawk开关，加载客服代码
    useEffect(() => {
        if (!tawk_switch) {
            return;
        }
        (window as any).Tawk_API = (window as any)?.Tawk_API || {};

        (window as any).Tawk_LoadStart = new Date();

        // 用户登录后登录到客服
        (window as any).Tawk_API.onBeforeLoad = function () {
            setShowTawk(true);
            if (!userInfo.encryptUid) {
                return;
            }

            const { encryptUid, id, phone, display_name = '', email = '', phone_code = '' } = userInfo;
            (window as any).Tawk_API.login?.(
                {
                    hash: encryptUid,
                    userId: id, // required
                    name: display_name || '',
                    email: email || '',
                    ...(phone
                        ? {
                              phone: `${phone_code}${phone}`,
                          }
                        : {}),
                },
                function (error: string) {
                    if (error) {
                        console.log('tawk登录失败error：', error);
                        return;
                    }
                    console.log('tawk登录成功');
                },
            );
        };

        const script = document.createElement('script');
        script.src = `https://embed.tawk.to/${tawkInfo[0]}/${tawkInfo[1]}`;
        script.async = true;
        script.charset = 'UTF-8';
        script.crossOrigin = '*';
        document.body.appendChild(script);
    }, [tawk_switch, userInfo]);

    // 监听多语言，切换客服配置
    useEffect(() => {
        const $window: any = window;
        if (!tawk_switch || typeof window === 'undefined' || !$window.Tawk_API) {
            return;
        }

        $window?.Tawk_API?.switchWidget?.(
            {
                propertyId: tawkInfo[0],
                widgetId: tawkInfo[1],
            },
            function (error: any) {
                $window?.Tawk_API?.hideWidget();
                if (error) {
                    console.log('tawkError', error);
                }
            },
        );
    }, [lang, tawkInfo, tawk_switch]);

    const onTawkClick = () => {
        if (!localStorage.getItem('Authorization')) {
            (window as any)?.onLogin?.();
            message.error(intl.get('Common.PleaseLogInFirst').d('请先登录'));
            return;
        }

        const $window: any = window;
        if (!$window.Tawk_API || $window?.$socket) {
            return;
        }
        $window.Tawk_API?.maximize?.();
    };

    return {
        /** 是否展示客服 */
        showTawk,
        /** 点击客服 */
        onTawkClick,
    };
}

export default useTawk;
