import { useEffect, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import { Radio, Space } from 'antd';
import { useRouter } from 'next/router';
import axios from 'axios';

import useCustomModal from '@/reconstruction/components/public/CustomModal';
import { langItems, mtsLangMap, mtsTypeList } from '@/constants/mts';
import type { LocaleType } from '@/type/mts';

import styles from './index.module.less';

function useLanguage({ currentLang }: { currentLang: LocaleType }) {
    const [customModal, CustomModal] = useCustomModal();
    const router = useRouter();

    const [browserLanguageMts, setBrowserLanguageMts] = useState<any>(); // 浏览器语言

    // 获取浏览器语言的mts数据
    useEffect(() => {
        const browserLanguage = mtsTypeList.includes(navigator.language) ? navigator.language : 'en';
        const langJson = mtsLangMap[browserLanguage];

        axios
            .get(
                `https://mts-xds-dev.oss-cn-shenzhen.aliyuncs.com/multilingual-translation-system/hawk_website/${langJson}.json`,
            )
            .then((res: any) => {
                const { status, data } = res || {};
                if (status === 200) {
                    setBrowserLanguageMts(data);
                }
            });
    }, []);

    /** 语言切换 */
    const langFilterItems = useMemo(() => {
        return langItems.filter((item) => {
            return item.key !== currentLang;
        });
    }, [currentLang]);

    const [value, setValue] = useState<string>(langFilterItems[0].key || 'en');

    useEffect(() => {
        setValue(langFilterItems[0].key);
    }, [langFilterItems]);

    /** 单选切换 */
    const onChange = (e: any) => {
        setValue(e.target.value);
    };

    /** 处理单选 */
    const RadioRender = useMemo(() => {
        return (
            <Radio.Group onChange={onChange} value={value} style={{ width: '100%' }}>
                <Space direction="vertical" className={styles.wrapper}>
                    {langFilterItems.map((item) => {
                        return (
                            <Radio className={styles.radio_item} key={item.key} value={item.key}>
                                {item.label}
                            </Radio>
                        );
                    })}
                </Space>
            </Radio.Group>
        );
    }, [value, langFilterItems]);

    const replaceUrl = () => {
        router.replace(router.asPath, router.asPath, { locale: value });
        customModal.close();
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const browserLanguage = navigator.language || 'en';

            if (browserLanguage !== currentLang) {
                customModal.confirm({
                    open: true,
                    children: RadioRender,
                    cancelText: browserLanguageMts?.Common?.Cancel || intl.get('Common.Cancel').d('取消'),
                    okText: browserLanguageMts?.Common?.Confirm || intl.get('Common.Confirm').d('确认'),
                    title:
                        browserLanguageMts?.Common?.MTSModalTitle ||
                        intl
                            .get('Common.MTSModalTitle')
                            .d('当前官网显示语言与系统语言不一致，您可以选择更换语言。取消、确认。'),
                    onOk: () => {
                        // 切换语言
                        replaceUrl();
                    },
                });
            }
        }
    }, [currentLang, browserLanguageMts, RadioRender]);

    return {
        CustomModal,
    };
}

export default useLanguage;
