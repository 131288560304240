/**
 * postLoginCallback
 */
export const postLoginCallback = (res: any, router: any, callBack: any, cookie: any, onCancel: any) => {
    const { state, data } = res;
    const { uauthCode, type } = data || {};
    if (Number(state) === 1) {
        localStorage.setItem('Authorization', uauthCode);
        localStorage.setItem('userInfo', JSON.stringify(data));

        const { nickname, uid, icon, commentToken, commentAvatar, commentObjectId, commentMailMd5, encryptUid } =
            data || {};
        const userKey = 'WALINE_USER';
        const storage = {
            display_name: nickname,
            email: uid,
            remember: true,
            token: commentToken,
            avatar: icon || commentAvatar,
            objectId: commentObjectId,
            mailMd5: commentMailMd5,
            encryptUid: encryptUid,
        };

        localStorage.setItem(userKey, JSON.stringify(storage));

        cookie?.('AUTH_TOKEN', uauthCode, { path: '/', sameSite: true });
        const typeNum = Number(type || 0);

        switch (typeNum) {
            case 1: // 1: 需要先设置密码
                callBack?.('set');
                break;
            case 2: // 2: 需要先绑定手机或邮箱并设置密码
                callBack?.('bind');
                break;
            default:
                onCancel?.();
                if (router.asPath.includes('/login/callback')) {
                    window.history.go(-2); // 返回两级，跳过第三方登录页
                } else {
                    router.reload();
                }
                break;
        }
    } else {
        // 返回登录流程
        callBack?.('normal');
    }
};
