import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import intl from 'react-intl-universal';
import c from 'classnames';

import { NextImage, HeaderMarket } from '@/components';
import { getFeedbackUnreadAsync, getUserInfoAsync, setTopHeaderHeight } from '@/store/config';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import { addOrUpdateQueryParam, currentLocaleUrl, resizeObserverFun } from '@/utils';
import { useBreadcrumbsRender } from '@/hooks';
import { noShowHead } from '@/constants/breadcrumbs';

import {
    HeaderMenu,
    HeaderNav,
    SearchInput,
    MtsLang,
    WhatsAPP,
    LoginBtn,
    RollArticleTitle,
    ChildNav,
} from '../components';
import { codeList } from '../config';
import type { HeaderProps } from '../types';
import styles from './index.module.less';

const Header: React.FC<HeaderProps> = (props) => {
    const noNavList = ['/broker/[brokerId]', '/center', '/center/modify'];
    const { menus = [], logo = '', isApp = false, topBanner = {}, showTopTW, topTWCode } = props;
    const router = useRouter();
    const { pathname, query, locale } = router;

    const dispatch = useAppDispatch();
    const { topChildHeight = 0, imgPrefix, showBarkly } = useAppSelector((state: any) => state.config);

    // ------------------ 面包屑 ------------------
    const { breadcrumbsRender } = useBreadcrumbsRender();
    // ------------------ 面包屑 ------------------

    // ------------- 头部整体高度
    const headerRef = useRef<any>(null);
    const [pcHeaderHeight, setPcHeaderHeight] = useState<number>(60);
    useEffect(() => {
        resizeObserverFun(headerRef.current, setPcHeaderHeight, 'clientHeight');
    }, []);

    // ------------- 头部header_top高度
    const headerTopRef = useRef<any>(null);
    const [pcHeaderTopHeight, setPcHeaderTopHeight] = useState<number>(60);
    useEffect(() => {
        resizeObserverFun(headerTopRef.current, setPcHeaderTopHeight, 'clientHeight');
    }, []);

    // ------------- search
    const [hideNav, onHideNav] = useState<boolean>(noNavList.includes(pathname));

    useEffect(() => {
        dispatch(setTopHeaderHeight(pcHeaderHeight || 60));
    }, [pcHeaderHeight]);

    // wrapper Ref
    const rightRef = useRef<any>(null);
    const [rightWidth, setRightWidth] = useState<number>(466);
    useEffect(() => {
        resizeObserverFun(rightRef.current, setRightWidth);
    }, []);
    useEffect(() => {
        if (localStorage.getItem('Authorization')) {
            dispatch(getUserInfoAsync());
            dispatch(getFeedbackUnreadAsync());
        }
    }, [dispatch]);

    const routerGo = (path: string) => {
        router.push(path);
    };

    /**
     * 头图加载完成，设置顶部高度
     * @param e
     */
    const onTopBannerLoad = (e: any) => {
        dispatch(setTopHeaderHeight((pcHeaderHeight || 60) + e.target.clientHeight));
    };

    /** 是否展示面包屑 */
    const showCrumbs = useMemo(() => {
        return !noShowHead.includes(pathname);
    }, [pathname]);

    return (
        <>
            <header
                id="web-header"
                className={c(styles.header, { [styles.app_header]: isApp, [styles.barkly_container]: showBarkly })}
            >
                <div className={styles.header_top} ref={headerTopRef}>
                    {topBanner.visible && topBanner.url && (
                        <div className={styles.top_banner}>
                            <NextImage src={topBanner.url} layout="responsive" alt="banner" onLoad={onTopBannerLoad} />
                        </div>
                    )}

                    {showTopTW && topTWCode && <HeaderMarket elementId="headerMarket" code={topTWCode} />}

                    <div className={styles.header_box}>
                        <div className={styles.header_left}>
                            <HeaderMenu />
                            {logo && (
                                <h1 className={styles.logo}>
                                    <a href={currentLocaleUrl('/')}>
                                        <NextImage
                                            src={showBarkly ? `${imgPrefix}/logo/logo-pc.png` : logo}
                                            layout="fill"
                                            alt="HawkInsight"
                                        />
                                    </a>
                                </h1>
                            )}
                            {isApp && (
                                <span
                                    className={styles.return_home}
                                    onClick={() => {
                                        routerGo('/');
                                    }}
                                >
                                    {intl.get('Live.ReturnHome').d('返回首页')}
                                </span>
                            )}
                        </div>

                        <div className={c(styles.header_right)}>
                            <div className={styles.nav_con} style={{ width: `calc(100% - ${rightWidth + 1}px)` }}>
                                {!isApp && (
                                    <RollArticleTitle
                                        defaultShow={
                                            <>
                                                <HeaderNav menus={menus} hideNav={hideNav} />
                                                <SearchInput search={pathname === '/search'} onHideNav={onHideNav} />
                                            </>
                                        }
                                    />
                                )}
                            </div>

                            <ul className={styles.code_list} ref={rightRef}>
                                {[...codeList].map((item: any) => {
                                    const { name, mts, icon, list, link } = item;
                                    return (
                                        <li key={name}>
                                            {list?.length ? (
                                                <WhatsAPP dataSource={item} />
                                            ) : (
                                                <Link href={`${link}?formerCrumbsId=${query?.crumbsId}`}>
                                                    <div className={styles.down}>
                                                        <span className={c(styles.icon, 'iconfont', icon)} />
                                                        <span className={styles.name}>
                                                            {mts ? intl.get(mts).d(name) : name}
                                                        </span>
                                                    </div>
                                                </Link>
                                            )}
                                        </li>
                                    );
                                })}

                                <li className={styles.lang}>
                                    <MtsLang locale={locale} />
                                </li>
                                <li className={styles.extends}>
                                    <LoginBtn locale={locale} />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {!isApp && <ChildNav menus={menus} top={pcHeaderTopHeight} />}
            </header>
            <div
                className={styles.header_height}
                ref={headerRef}
                style={{ height: pcHeaderTopHeight + topChildHeight }}
            >
                <div className={styles.header_top} style={{ height: pcHeaderTopHeight }} />
            </div>
            {showCrumbs && breadcrumbsRender}
        </>
    );
};

export default Header;
