import axios from 'axios';
import { Cookies } from 'react-cookie';
import intl from 'react-intl-universal';

import { apiLangHeaderMap, defaultLocale } from '@/constants/mts';

import { currentLocaleUrl } from './util';

const isProd = process.env.NODE_ENV === 'production';

const responseStateHandel = (state: number, msg?: string, t?: boolean) => {
    // const { request } = response || {};
    // const { responseURL = '' } = request || {};
    // const apiList = [
    //     '/japi/appUser/verification',
    //     '/japi/appUser/editPhone',
    //     '/japi/appUser/editEmail',
    //     '/japi/appUser/editPassword',
    //     '/japi/appUser/editPasswordCheck',
    // ];
    // const obj = apiList.find((item: string) => {
    //     return responseURL.includes(item);
    // });
    if (t) {
        // 不使用默认message错误提示
        return;
    }
    switch (state) {
        case 1: // 成功
            break;
        case 10014: // 未登录
            const { pathname, search } = globalThis?.window?.location || {};
            const redirect = encodeURIComponent(`${pathname}${search}`);
            if (globalThis.window) {
                const cookies = new Cookies();
                cookies?.remove('AUTH_TOKEN', { path: '/' });
                window?.localStorage.removeItem('Authorization');
                window?.sessionStorage?.setItem('redirect', redirect || '/');
                setTimeout(() => {
                    (window as any)?.onLogin?.();
                });
            }
            break;
        default:
            if (globalThis.window) {
                window.antdMessage.error(intl.get(`ErrorCode.${state}`).d(msg || ''));
            }
            break;
    }
};

class Request {
    instance: any;
    imageRootHost: any;
    isFetching: any;
    constructor() {
        const instance = axios.create({
            timeout: 30000,
            withCredentials: true,
        });

        // 添加请求拦截器
        const interceptor = instance.interceptors.request.use(
            async (config) => {
                if (!this.imageRootHost) {
                    if (!this.isFetching) {
                        this.isFetching = true;
                        this.imageRootHost = await axios.get(
                            `${globalThis.window ? '/pcApi' : process.env.API_HOST}/base/common_api/pc`,
                            {
                                headers: {
                                    'Accept-Language':
                                        apiLangHeaderMap[intl.getInitOptions().currentLocale || defaultLocale] || '',
                                },
                            },
                        );
                        const { data } = this.imageRootHost || {};
                        if (data?.state === 1 && globalThis?.window) {
                            const { image_root_host } = data?.data || {};
                            const { environment } = image_root_host || {};
                            window?.localStorage?.setItem('environment', environment);
                        }
                        instance.interceptors.request.eject(interceptor);
                    }
                }
                return config;
            },
            function (error) {
                // 对请求错误做些什么
                if (globalThis.window) {
                    window.antdMessage.error(intl.get('Common.RequestFailed').d('请求失败！'));
                }
                return Promise.reject(error);
            },
        );

        // 添加响应拦截器
        instance.interceptors.response.use(
            (response) => {
                const { data, config } = response || {};
                const { state, stat, msg } = data || {};
                //@ts-ignore
                const { noMsg } = config || {};
                const status = Number(state || stat);
                responseStateHandel(status, msg, noMsg);

                if (this.imageRootHost) {
                    // 接口返回的资源类数据全部替换域名
                    try {
                        let resData = JSON.stringify(response.data);
                        const { needReplace, newDomain, oldDomain = [] } = this.imageRootHost.data.data.image_root_host;
                        if (needReplace && newDomain) {
                            [...oldDomain].forEach((domain: string) => {
                                resData = resData.replaceAll(domain, newDomain);
                            });
                        }
                        return JSON.parse(resData);
                    } catch {
                        return response.data;
                    }
                }
                return data;
            },
            function (error) {
                if (globalThis.window) {
                    const msg =
                        (error && error.response && error.response.data && error.response.data.msg) ||
                        intl.get('Common.RequestFailed').d('请求失败！');
                    window.antdMessage.error(msg);
                }
                // 对响应错误做点什么
                return Promise.reject(error);
            },
        );
        this.instance = instance;
    }
    getUrl(url: string) {
        // node直接请求，浏览器需要走代理
        const origin = globalThis.window && isProd ? location?.origin : '';
        return !globalThis.window ? `${process.env.API_HOST}/${url}` : `${origin}/pcApi${url}`;
    }
    getExtra(extra?: any) {
        const lang: { 'Accept-Language'?: string } = {};
        const auth: { Authorization?: string } = {};

        if (globalThis.window) {
            lang['Accept-Language'] = apiLangHeaderMap[intl.getInitOptions().currentLocale || defaultLocale] || 'zh';
        } else {
            const { locale } = extra || {};
            lang['Accept-Language'] = apiLangHeaderMap[locale || defaultLocale] || 'zh';
        }

        if (globalThis.window && localStorage.getItem('Authorization')) {
            auth.Authorization = `Bearer ${localStorage.getItem('Authorization')}`;
        }

        return {
            ...extra,
            headers: {
                ...lang,
                ...auth,
                ...(extra?.headers || {}),
            },
        };
    }
    get(url: string, data?: any, extra?: any) {
        return this.instance.get(this.getUrl(url), {
            params: data,
            ...this.getExtra(extra),
        });
    }
    post(url: string, data?: any, extra?: any) {
        return this.instance.post(this.getUrl(url), data, this.getExtra(extra));
    }
    put(url: string, data?: any, extra?: any) {
        return this.instance.put(this.getUrl(url), data, this.getExtra(extra));
    }
    delete(url: string, data?: any, extra?: any) {
        return this.instance.delete(this.getUrl(url), {
            params: data,
            ...this.getExtra(extra),
        });
    }
}

const request: any = new Request();

export default request;
