import React, { useState, useEffect, useMemo, useCallback, useRef, useImperativeHandle } from 'react';
import { App } from 'antd';
import { useAppSelector } from '@/store/hook';
import Expand from './components/Expand';
import PackUp from './components/PackUp';
import c from 'classnames';
import { getBrokerTopOrRecList } from '@/services/broker';
import cloneDeep from 'lodash/cloneDeep';
import intl from 'react-intl-universal';
import { currentLocaleUrl } from '@/utils';
import type { BrokerContrastProps } from './types';
import styles from './index.module.less';

const BrokerContrast: React.FC<BrokerContrastProps> = ({ imgPrefix, onRef }) => {
    const locale = intl.getInitOptions().currentLocale;

    const { message } = App.useApp();
    const storageName = 'CONTRAST_LIST';
    const { topHeaderHeight } = useAppSelector((state: any) => state.config);

    // 控制select
    const selRef = useRef<any>(null);

    // 展开、收起
    const [open, setOpen] = useState<boolean>(false);
    // 推荐列表
    const [recommendList, setRecommendList] = useState<any>([]);

    // 交易商对比列表
    const [contrastList, setContrastList] = useState<any[]>([]);

    useEffect(() => {
        const conList: any[] = JSON.parse(localStorage.getItem(storageName) || '[]');
        setContrastList(conList);
    }, []);

    useEffect(() => {
        localStorage.setItem(storageName, JSON.stringify(contrastList));
    }, [contrastList]);

    useEffect(() => {
        getBrokerTopOrRecList({ is_top_or_recommend: '1', state: 1 }).then((res: any) => {
            const { state, data } = res || {};
            if (state === 1) {
                const { list = [] } = data || {};
                setRecommendList(list);
            }
        });
    }, [locale]);

    /**
     * 错误回调
     * @param call
     */
    const showError = useCallback(
        (msg: string, call?: (a: string) => void) => {
            if (call) {
                call?.(msg);
            } else {
                message.warning(msg);
            }
        },
        [message],
    );

    /**
     * 添加交易商
     * @param record
     */
    const onAddItem = useCallback(
        (record: any, errorCall?: (a: string) => void, succeseCall?: () => void) => {
            const { id, logo, type, name_abbreviation, score, seo_url } = record;
            const cloneList = cloneDeep(contrastList || []);
            if (cloneList.length === 3) {
                showError(intl.get('Broker.AddUpToThree').d('最多添加3个交易商进行对比'), errorCall);
                return;
            }

            if (cloneList.find((item: any) => item?.id === record?.id)) {
                showError(intl.get('Broker.BrokerLWarnMsg').d('该交易商已在对比列表'), errorCall);
                return;
            }

            if (cloneList.find((item: any) => item.id && item?.type !== record?.type)) {
                showError(intl.get('Broker.OtherLWarnMsg').d('仅支持同种类型交易商进行对比'), errorCall);
                return;
            }

            succeseCall?.();
            cloneList.unshift({ id, logo, type, name_abbreviation, score, seo_url, timer: Date.now() });
            setContrastList(cloneList);
        },
        [contrastList, showError],
    );

    /**
     * 删除对比交易商
     * @param id
     */
    const onDelItem = useCallback(
        (id: string) => {
            const cloneList = cloneDeep(contrastList || []);
            const newList = cloneList.filter((item) => item.id !== id);
            setContrastList(newList);
        },
        [contrastList],
    );

    /**
     * 前往交易商详情
     * @param record
     */
    const onGoToDetail = useCallback((record: any) => {
        const { id, seo_url }: { id: string; seo_url: string } = record;
        const _url: string = currentLocaleUrl(`/broker/${seo_url || id}`);
        window.open(_url);
    }, []);

    /**
     * 前往对比
     */
    const onGoToContrast = useCallback(() => {
        if (contrastList.length === 0) {
            return;
        }
        localStorage.removeItem(storageName);
        const idstr = contrastList.map((item: any) => item?.id).join('.');
        const _url: string = currentLocaleUrl(`/page/Pc_Barterer_Comparison?ids=${encodeURIComponent(idstr)}`);
        window.open(_url);
    }, [contrastList]);

    // 暴露方法给父级组件
    useImperativeHandle(onRef, () => {
        return {
            // 添加对比
            addItem: (record: any) => {
                const cloneList = cloneDeep(contrastList || []);

                if (cloneList.find((item: any) => item?.id === record?.id)) {
                    showError(intl.get('Broker.BrokerLWarnMsg').d('该交易商已在对比列表'));
                    return;
                }

                if (cloneList.find((item: any) => item.id && item?.type !== record?.type)) {
                    showError(intl.get('Broker.OtherLWarnMsg').d('仅支持同种类型交易商进行对比'));
                    return;
                }

                if (cloneList.length === 3) {
                    cloneList.pop();
                }

                cloneList.unshift({ ...record, timer: Date.now() });
                setContrastList(cloneList);
            },
        };
    });

    // 默认组件参数
    const componentParmas = useMemo(() => {
        return {
            onRef: selRef,
            imgPrefix,
            contrastList,
            recommendList,
            contrastonRef: onRef,
            onOpen: setOpen,
            onAddItem,
            onDelItem,
            onGoToDetail,
            onGoToContrast,
            setContrastList,
        };
    }, [contrastList, imgPrefix, recommendList, onAddItem, onDelItem, onGoToDetail, onGoToContrast]);

    return (
        <div
            className={c({ [styles.drawer]: true, [styles.drawerOpen]: open })}
            style={{ paddingTop: topHeaderHeight }}
        >
            {open ? <Expand {...componentParmas} /> : <PackUp {...componentParmas} />}
        </div>
    );
};

export default BrokerContrast;
