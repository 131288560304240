// 用于处理面包屑相关的内容
import intl from 'react-intl-universal';

import { systemPageNameMap } from '@/constants/page';
import { ArticleNameMtsMap } from '@/constants/breadcrumbs';

import { getNavId, getSelectKeyItem } from './home';
import { formatMts } from './mts';
import { addOrUpdateQueryParam, currentLocaleUrl } from './util';

/**
 * 复制sessionStorage到localStorage
 * 用于跳转新标签页后,复制sessionStorage到localStorage,以便新标签页可以获取到sessionStorage中的数据,用于读取前一步的面包屑数据
 */
export const copySessionStorageToLocalStorage = () => {
    window.localStorage.setItem('sessionStorageBackup', JSON.stringify(sessionStorage));
};

/** 获取当前页面路径上的指定参数 */
export const getCurrentUrlQueryParam = (key?: string) => {
    const { href } = window?.location || {};
    const urlobj = new URL(href);

    if (key) {
        return urlobj.searchParams.get('crumbsId') || '';
    }

    return '';
};

// 拦截 a 标签增加参数面包屑id
const jumpAddCrumbs = (element: any) => {
    const urlHref = element.getAttribute('href');
    const target = element.getAttribute('target') || '_self';
    const type = element.getAttribute('data-type');

    if (!urlHref || type === 'file') {
        // 防止无 href和文件 的 a 标签被拦截
        return;
    }

    const crumbsId = getCurrentUrlQueryParam('crumbsId');

    if (!crumbsId) {
        window.open(currentLocaleUrl(urlHref), target);
        return;
    }

    // 携带面包屑进行跳转,并且携带前一步的面包屑数据
    const newUrl = addOrUpdateQueryParam(urlHref, 'formerCrumbsId', crumbsId);

    // 存储sessionStorage到localStorage,同步面包屑数据
    copySessionStorageToLocalStorage();

    window.open(currentLocaleUrl(newUrl), target);
};

/** 拦截a标签 */
export const addHrefEventListener = () => {
    // 定义处理函数
    const handleClick = (event: Event) => {
        const target = event.currentTarget as HTMLElement;
        if (target.tagName === 'A') {
            // 阻止默认行为
            event.preventDefault();
            jumpAddCrumbs(target); // 面包屑处理
        } else {
            // 如果点击的是 a 标签内的子元素
            const anchor = (event.target as HTMLElement).closest('a');
            if (anchor) {
                if (event.defaultPrevented) {
                    // 如果事件默认行为已被阻止，则不执行跳转
                    return;
                }
                // 阻止默认行为
                event.preventDefault();
                jumpAddCrumbs(anchor); // 面包屑处理
            }
        }
    };

    // 移除可能存在的旧监听器
    document.removeEventListener('click', handleClick);

    // 添加新的监听器
    document.addEventListener('click', handleClick);

    // 处理PC的侧边导航
    const navBarEl = document.getElementById('navBar');
    navBarEl?.removeEventListener('click', handleClick);
    navBarEl?.addEventListener('click', handleClick);
};

/** 拦截window.open,增加面包屑id */
export const addWindowOpenEventListener = () => {
    const originalOpen = window.open;
    window.open = function (url, ...args) {
        const crumbsId = getCurrentUrlQueryParam('crumbsId');

        const newUrl = addOrUpdateQueryParam(url as string, 'formerCrumbsId', crumbsId);

        // 执行代码
        return originalOpen.call(window, newUrl, ...args);
    };
};

/** 新增面包屑数据到面包屑列表 */
export const addBreadcrumbsList = (data: any, list: any[]) => {
    const newList = list.filter((item) => {
        return item.grade < data.grade;
    });
    newList.push(data);
    return newList;
};

/** 处理面包屑名称 */
export const disposeBreadcrumbsName = ({ result, pageProps, router }: { result: any; pageProps: any; router: any }) => {
    const { globalData = {} }: any = pageProps || {};
    const { menus = [] }: { menus: any[] } = globalData;
    const { pathname, query } = router;

    const { firstId, pageId, secondId }: { firstId?: string; pageId?: string; secondId?: string } = query || {};

    switch (pathname) {
        // 导航页
        case '/[firstId]':
        case '/[firstId]/[secondId]':
            const firstNav = menus.find((item: any) => [item.inventedUrl, item.id].includes(firstId));

            const id = getNavId(firstId || '', secondId || '', firstNav);
            const currentNav: any = id ? getSelectKeyItem(menus, id) : {};

            const { nameMts } = currentNav;
            result.name = formatMts(nameMts) || result.name;
            delete result.mts; // 删除mts字段
            result.mtsMap = nameMts; // 保留mts内容字段

            if (secondId) {
                return [
                    {
                        url: `/${firstId}`,
                        grade: 1,
                        name: formatMts(firstNav?.nameMts) || firstNav.name || '',
                    },
                    result,
                ];
            }
            break;
        // 交易商详情页
        case '/broker/[brokerId]':
            const { name_abbreviation = '' } = pageProps?.brokerData || {};
            result.name = name_abbreviation || result.name;
            delete result.mts; // 删除mts字段
            break;
        // 交易商榜单详情
        case '/broker/list/detail/[listId]':
            const dataSource = pageProps?.dataSource || {};
            result.name = dataSource?.name || result.name;
            delete result.mts; // 删除mts字段
            break;
        // 专栏详情
        case '/topic/[topicId]':
            const topic_info = pageProps?.topicsData?.topic_info || {};
            result.name = topic_info?.title || result.name;
            delete result.mts; // 删除mts字段
            break;
        // 创作者详情
        case '/ugc/[ugcId]':
            const ugcData = pageProps?.ugcData || {};
            result.name = ugcData?.name || result.name;
            delete result.mts; // 删除mts字段
            break;
        // 展示页面
        case '/page/[pageId]':
            const pageData = pageProps?.pageData?.props || {};
            const systemPageItem = systemPageNameMap[pageId || ''] || {};
            let systemPageName = '';
            if (systemPageItem.mts) {
                systemPageName = intl.get(systemPageItem.mts).d(systemPageItem.name || '');
            }
            result.name = systemPageName || formatMts(pageData?.titleMts) || result.name;
            delete result.mts; // 删除mts字段
            result.mtsMap = systemPageItem.mts || pageData?.titleMts; // 保留mts内容字段
            break;
        // 文章详情
        case '/article/[...param]':
            const { category = 'common' } = pageProps?.articleData || {};
            result.name = intl.get(ArticleNameMtsMap[category]).d(result.name);
            result.mts = ArticleNameMtsMap[category]; // 删除mts字段
            break;
    }

    return [result];
};
