import React, { useMemo, useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import type { ImageProps } from 'next/image';
import c from 'classnames';

import { useAppSelector } from '@/store/hook';
import { filters } from '@/utils';
import { defaultLocale, langImgPrefix } from '@/constants/mts';

import styles from './index.module.less';

interface NextImgProps extends ImageProps {
    isLang?: boolean; // 是否有多语言图片【如英文图片放入oss需以en_开头】
    className?: any;
    needPrefix?: boolean;
    place?: 'default' | 'normal' | 'place' | string;
    onLoad?: (e: any) => void;
}

type StaticImport = /*unresolved*/ any;

interface LoaderProps {
    src: string;
    width?: number;
    quality?: number;
}

const NextImage: React.FC<NextImgProps> = (props) => {
    const { imgPrefix } = useAppSelector((state: any) => state.config);
    const { src, className, needPrefix, place, objectFit, isLang, ...resProps } = props;
    const { locale } = useRouter();

    const prefix: string = useMemo(() => {
        return needPrefix ? imgPrefix : '';
    }, [imgPrefix, needPrefix]);

    const setErrorImg = (place?: string) => {
        return `/img/place/img_${place || 'place'}.webp`;
    };

    const [imgSrc, setImgSrc] = useState<string | StaticImport>(src || setErrorImg(place || 'place'));
    const [isError, setIsError] = useState<boolean>(!src);

    useEffect(() => {
        setIsError(!src);
        setImgSrc(src || setErrorImg(place || 'place'));
    }, [src, place]);

    //根据语言找出src最后的图片名进行替换
    const replaceImgName = (imgUrl: string) => {
        // 在路径部分查找最后一个斜杠的位置并提取其后面的内容
        const lastSlashIndex = imgUrl.lastIndexOf('/');
        let lastSegment = lastSlashIndex !== -1 ? imgUrl.substring(lastSlashIndex + 1) : '';
        lastSegment = imgUrl.replace(lastSegment, langImgPrefix[locale || defaultLocale] + lastSegment);
        return lastSegment;
    };

    const imgLoader = ({ src, width, quality }: LoaderProps) => {
        if (src && !isError) {
            let newSrc = src;
            // 多语言图片处理，
            if (isLang) {
                newSrc = replaceImgName(newSrc);
            }
            const compressPath = `${prefix}${newSrc}`;
            if (compressPath.indexOf('?') !== -1) {
                return `${compressPath}&w=${width}&q=${quality || 100}`;
            } else {
                return `${compressPath}?w=${width}&q=${quality || 100}`;
            }
        } else {
            return setErrorImg(place);
        }
    };

    return (
        <Image
            src={imgSrc}
            alt="Hawk Insight"
            className={c(className, styles.img, { [styles.error]: isError })}
            loader={isError ? null : imgLoader}
            onError={() => {
                setIsError(true);
                setImgSrc(setErrorImg(place));
            }}
            onLoadingComplete={(result: any) => {
                if (result.naturalWidth === 0) {
                    setIsError(true);
                    setImgSrc(setErrorImg(place));
                }
            }}
            {...filters(resProps)}
            objectFit={objectFit || (isError ? 'contain' : 'cover')}
        />
    );
};

export default NextImage;
