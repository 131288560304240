import { apiLangHeaderMap, defaultLocale, getMultiInputLang } from '@/constants/mts';
import intl from 'react-intl-universal';

/**
 * 获取当前语言对应的 key
 * @param currentLocale
 * @returns
 */
export const getMtsKey = (currentLocale: string) => {
    return getMultiInputLang[currentLocale] || getMultiInputLang[0];
};

/**
 * 获取当前语言对应的 key
 * @param currentLocale
 * @returns
 */
export const getMtsLocaleKey = (currentLocale: string) => {
    return apiLangHeaderMap[currentLocale] || apiLangHeaderMap[0];
};

/**
 * 设置当前配置的语言
 * @param mts
 * @returns
 */
export const formatMts = (mts?: Record<string, string>) => {
    const lang: string = intl.getInitOptions().currentLocale || defaultLocale;
    return (mts || {})[getMtsKey(lang)];
};

/**
 * 设置当前配置的MEAT SEO语言
 * @param mts
 * @returns
 */
export const formatNavSeoMts = (zh_cns: string, mts?: Record<string, string>, locale?: string) => {
    const zh: Record<string, string> = zh_cns ? { zh_cns } : {};
    const lang: string = locale || intl.getInitOptions().currentLocale || '';
    return lang ? { ...(mts || {}), ...zh }[getMtsKey(lang)] || '' : '';
};

/**
 * 设置当前Broker 业务类型
 * @param mts
 * @returns
 */
export const formatBrokerBusiness = (name: string, locale?: string) => {
    return (name || '').substring(0, locale !== 'zh-CN' ? 8 : 4);
};
