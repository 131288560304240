import React, { useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import _ from 'lodash';

import InfiniteScroll from '../../../../public/InfiniteScroll';
import { TagTitle } from '../../../../public';
import intl from 'react-intl-universal';
import FlashNewsList from './list';
import { useAppSelector, useAppDispatch } from '@/store/hook';
import { setFlashNewsHeight } from '@/store/config';
import { apiLangHeaderMap, defaultLocale } from '@/constants/mts';
import { currentLocaleUrl, formatDomUnit, getVisualSkipPage } from '@/utils';
import request from '@/utils/axios';

import { IProps } from '../../type';

import styles from './index.module.less';

interface Props extends IProps {}

const NewsFlash: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();
    const { navsId = [], serviceData, customStyle, isCeiling, isNavHeight, groupTitleUrl } = props;
    const InfiniteScrolleRef = useRef<any>(); // 滚动列表 ref
    const newFlashTimer = useRef<any>(); // 获取新快讯信息的定时器 ref
    const listRef = useRef<any[]>([]); // 列表数据 ref
    const listContainerRef = useRef<any>(); // 列表容器 ref
    const flashRef = useRef<any>(null); // 整个组件 ref

    const { topHeaderHeight } = useAppSelector((state: any) => state.config);

    /** 取消定时 */
    const cancelTimingHandler = () => {
        clearInterval(newFlashTimer.current);
    };

    /** 触顶触发 */
    const topHandler = () => {
        cancelTimingHandler();
        newFlashTimer.current = setInterval(() => {
            fetchData();
        }, 120000);
    };

    useLayoutEffect(() => {
        // 初始执行一次
        topHandler();

        const elm = listContainerRef.current?.scrollerElement;
        // 监听滚动事件
        elm?.addEventListener?.(
            'scroll',
            _.debounce(() => {
                if (elm?.scrollTop === 0) {
                    // 在顶部时执行相应操作
                    topHandler();
                } else {
                    cancelTimingHandler();
                }
            }, 100),
        );
        return () => {
            // 取消定时
            cancelTimingHandler();
        };
    }, []);

    /** 快讯高度 */
    const quickHeight = useMemo(() => {
        if (isNavHeight) {
            return 1145;
        }
        return 620;
    }, [isNavHeight]);

    // 获取侧边快讯高度
    useEffect(() => {
        dispatch(setFlashNewsHeight(quickHeight));
    }, [dispatch, isNavHeight]);

    const fetchData = async (i?: number) => {
        const res = await request.get(
            '/japi/article/quick/list',
            {
                page: 1,
                pageSize: 20,
                ids: navsId.join(','),
            },
            {
                locale: apiLangHeaderMap[intl.getInitOptions().currentLocale || defaultLocale] || 'zh',
            },
        );
        if (res?.state === 1) {
            const resList: any[] = res?.data?.list || [];
            if (!resList.length) {
                return;
            }
            const oldKeys = new Set(listRef.current.map((obj) => obj.pk));
            const isExist = resList.every((obj) => oldKeys.has(obj.pk));

            if (!isExist) {
                InfiniteScrolleRef.current.resetList();
            }
        }
    };

    // 设置快讯上层容器滚动置顶
    useEffect(() => {
        const element = flashRef.current;

        if (element) {
            const parentElement = element.parentElement;

            if (parentElement && isCeiling) {
                parentElement.style.position = 'sticky';
                parentElement.style.top = `${topHeaderHeight}px`;
                parentElement.style.height = 'max-content';
            }
        }
    }, [isCeiling, topHeaderHeight]);

    return (
        <div
            style={{
                ...formatDomUnit(customStyle || {}),
            }}
            ref={flashRef}
        >
            <div
                className={styles.box}
                style={{
                    height: quickHeight,
                }}
            >
                <div className={styles.wrapper}>
                    {/* 头部 */}
                    {isNavHeight ? (
                        <div className={styles.top_box}>
                            <div className={styles.wrapper__title}>
                                <h2 className={styles.top_title}>
                                    {intl.get('PersonalCenter.Newest').d('最新')}
                                    {intl.get('PersonalCenter.Flash').d('快讯')}
                                </h2>
                            </div>

                            {groupTitleUrl && Object.keys(groupTitleUrl).length && (
                                <a
                                    className={styles.top_link}
                                    href={currentLocaleUrl(getVisualSkipPage(groupTitleUrl))}
                                    target="_blank"
                                >
                                    {intl.get('Common.SeeMore').d('查看更多')}
                                </a>
                            )}
                        </div>
                    ) : (
                        <div className={styles.flashNewsTitle}>
                            <TagTitle
                                text={[
                                    intl.get('PersonalCenter.Newest').d('最新'),
                                    intl.get('PersonalCenter.Flash').d('快讯'),
                                ]}
                                iconType="quick"
                                classsName={styles.flashNewsTitle_tag}
                            />
                            {groupTitleUrl && Object.keys(groupTitleUrl).length && (
                                <a
                                    className={styles.flashNewsTitle_more}
                                    href={currentLocaleUrl(getVisualSkipPage(groupTitleUrl))}
                                    target="_blank"
                                >
                                    {intl.get('Common.SeeMore').d('查看更多')}{' '}
                                    <i className="iconfont icon-arrow-right" />
                                </a>
                            )}
                        </div>
                    )}
                    {/* 主体信息 */}
                    <div className={styles.tabsCon} ref={listContainerRef}>
                        <InfiniteScroll
                            isInfiniteScrolling={true}
                            initialLoad={false}
                            useWindow={false}
                            serviceData={serviceData}
                            onRef={InfiniteScrolleRef}
                            api="/japi/article/quick/list"
                            extraParams={{ ids: navsId.join(',') }}
                            renderList={(list) => {
                                listRef.current = list;
                                return <FlashNewsList options={list} />;
                            }}
                        />
                    </div>
                    <div className={styles.tabsBottom} />
                </div>
            </div>
        </div>
    );
};

export default NewsFlash;
