import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import c from 'classnames';
import intl from 'react-intl-universal';

import { currentLocaleUrl, formatMts, PAGE_CENTER_NEW } from '@/utils';
import { useAppSelector } from '@/store/hook';

import styles from './index.module.less';

const useBreadcrumbsRender = () => {
    const router = useRouter();
    const currentLocale = intl.getInitOptions().currentLocale; // 当前语言

    const { showBarkly } = useAppSelector((state: any) => state.config);

    const { query } = router;
    const { crumbsId } = query;

    const [breadcrumbsList, setBreadcrumbsList] = useState<any[]>([]);
    const [isShowOmit, setIsShowOmit] = useState<boolean>(false);

    // 获取面包屑数据
    useEffect(() => {
        setTimeout(() => {
            const storedBreadcrumbs: any[] = JSON.parse(sessionStorage.getItem(crumbsId as string) || '[]');
            setBreadcrumbsList(storedBreadcrumbs);
        });
    }, [crumbsId, router, currentLocale]);

    /** 最后一个面包屑数据 */
    const lastBreadcrumb = useMemo(() => {
        return breadcrumbsList[breadcrumbsList.length - 1];
    }, [breadcrumbsList, currentLocale]);

    /** 是否展示省略 */
    useEffect(() => {
        setIsShowOmit(breadcrumbsList.length === 7);
    }, [breadcrumbsList]);

    const isNoShowCrumbs = useMemo(() => {
        return (lastBreadcrumb?.grade || 0) <= 2;
    }, [lastBreadcrumb]);

    /** 面包屑渲染 */
    const breadcrumbsRender = useMemo(() => {
        if (isNoShowCrumbs) {
            return null;
        }
        return (
            <div className={styles.wrapper}>
                <div
                    className={c(styles.breadcrumbs, { [styles.showBarkly]: showBarkly })}
                    style={{ width: PAGE_CENTER_NEW }}
                >
                    {breadcrumbsList.map((item, index) => {
                        const { url, name, mts, mtsMap } = item;
                        /** 是否隐藏 */
                        const isHide = isShowOmit && index > 0 && index < 6;

                        let newName = mts ? intl.get(mts).d(name) : name;

                        // 如果有记录多语言数据，直接去获取
                        if (mtsMap) {
                            newName = formatMts(mtsMap) || newName;
                        }

                        /** 展示省略号 */
                        if (isHide && index === 5) {
                            return (
                                <div className={c(styles.breadcrumb_itme)}>
                                    <div onClick={() => setIsShowOmit(false)} className={styles.title}>
                                        ......
                                    </div>
                                    <span className={c('iconfont', 'icon-mianbaoxiejiantou')} />
                                </div>
                            );
                        }

                        if (isHide) {
                            return;
                        }
                        return (
                            <div
                                key={url}
                                title={newName}
                                className={c(styles.breadcrumb_itme, {
                                    [styles.active]: index === breadcrumbsList.length - 1,
                                })}
                            >
                                <a href={currentLocaleUrl(url)} className={styles.title}>
                                    {newName}
                                </a>
                                <span className={c('iconfont', 'icon-mianbaoxiejiantou')} />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }, [isNoShowCrumbs, isShowOmit, breadcrumbsList, currentLocale]);

    return {
        /** 面包屑渲染 */
        breadcrumbsRender,
        /** 是否展示面包屑 */
        isNoShowCrumbs,
    };
};

export default useBreadcrumbsRender;
