import React, { useEffect, useRef, useCallback } from 'react';
import { type WalineInstance, type WalineInitOptions, init } from '@waline/client';
import { useAppSelector } from '@/store/hook';
import '@waline/client/dist/waline.css';
import styles from './index.module.less';
import c from 'classnames';
import jQuery from 'jquery';
import intl from 'react-intl-universal';
import { defaultLocale } from '@/constants/mts';

export type WalineOptions = Omit<WalineInitOptions, 'el'> & { path: string };

const Waline: React.FC<WalineOptions> = (props) => {
    const WALINE_EDITOR = 'WALINE_COMMENT_BOX_EDITOR';

    const { userInfo } = useAppSelector((state: any) => state.config);
    const { current: normalRef } = useRef({
        aTimer: null as any,
        loginTimer: null as any,
        fTimer: null as any,
        sTimer: null as any,
    });

    const walineInstanceRef = useRef<WalineInstance | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const nullTokenRef = useRef<HTMLDivElement>(null);

    // 初始化评论框
    const initWalineFun = useCallback(
        (ref: any, moreProps?: any) => {
            walineInstanceRef.current = init({
                ...props,
                lang: intl.getInitOptions().currentLocale || defaultLocale,
                search: false,
                copyright: false,
                el: ref.current,
                emoji: false,
                imageUploader: false,
                ...(moreProps || {}),
            });
        },
        [props],
    );

    // 初始化评论框
    useEffect(() => {
        localStorage.removeItem(WALINE_EDITOR);
        walineInstanceRef.current?.destroy();
        if (userInfo?.token) {
            initWalineFun(containerRef);
        } else {
            initWalineFun(nullTokenRef);
        }
    }, [userInfo, containerRef, nullTokenRef, initWalineFun]);

    /**
     * 监听的按钮点击、刷新waline
     */
    const onRefresh = useCallback(() => {
        localStorage.removeItem(WALINE_EDITOR);
        walineInstanceRef.current?.update({ path: 'normal-path-for-null' });
        if (normalRef.sTimer) {
            clearTimeout(normalRef.sTimer);
        }
        normalRef.sTimer = setTimeout(() => {
            walineInstanceRef.current?.update({ ...props });
        });
    }, [normalRef, props]);

    /**
     * 获取要监听的按钮
     */
    const addBtnEventListener = useCallback(() => {
        const subBtn = containerRef?.current?.querySelector(
            '#wl-wraper .wl-comment>.wl-panel>.wl-footer>.wl-info>button[type="submit"]',
        );

        const btnList: any = [subBtn];
        for (let i = 0; i < btnList.length; i++) {
            if (btnList[i]) {
                btnList[i].onclick = () => {
                    if (normalRef.fTimer) {
                        clearTimeout(normalRef.fTimer);
                    }

                    normalRef.fTimer = setTimeout(() => {
                        onRefresh();
                    }, 1000);
                };
            }
        }
    }, [normalRef, onRefresh]);

    /**
     * 监听页面btn点击
     */
    useEffect(() => {
        const walineBtn = setInterval(() => {
            addBtnEventListener();
        }, 1000);

        return () => walineBtn && clearInterval(walineBtn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (normalRef.aTimer) {
            clearInterval(normalRef.aTimer);
        }
        // 修改头像
        normalRef.aTimer = setInterval(() => {
            const nickInfo: any = containerRef?.current?.querySelectorAll('.wl-login-nick');
            const avatarInfo: any = containerRef?.current?.querySelector('.wl-login-nick img');
            if (avatarInfo && jQuery(avatarInfo).attr('src') !== userInfo?.avatar) {
                jQuery(avatarInfo).attr('src', userInfo?.avatar);
            }

            // 修改跳转
            (nickInfo || []).forEach((nickItem: any, i: number) => {
                if (i === 0 && jQuery(nickItem).attr('href') === '/center') return;

                if (
                    i === 1 &&
                    jQuery(nickItem).text() === userInfo?.nickname &&
                    jQuery(nickItem).attr('href') === '/center'
                )
                    return;

                const moreProps = i === 1 ? { text: userInfo?.nickname } : {};
                jQuery(nickItem).replaceWith(() => {
                    return jQuery('<a/>', {
                        html: nickItem.innerHTML,
                        class: nickItem.className,
                        href: '/center',
                        target: '_blank',
                        ...moreProps,
                    });
                });
            });
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo?.avatar, userInfo?.nickname]);

    useEffect(() => {
        if (!userInfo?.token) {
            const wlInfo: any = nullTokenRef?.current?.querySelector('.wl-info');
            const loginBt: any = nullTokenRef?.current?.querySelector('#login-btn');
            if (wlInfo && !loginBt) {
                const loginBtn: any = document.createElement('button');
                loginBtn.innerText = intl.get('Login.LoginBtn').d('登录');
                loginBtn.id = 'login-btn';
                loginBtn.className = 'login-btn';
                loginBtn.type = 'button';
                wlInfo.appendChild(loginBtn);

                loginBtn?.addEventListener('click', () => {
                    (window as any)?.onLogin?.();
                });
            }
        }
    }, [userInfo]);

    useEffect(() => {
        return () => {
            walineInstanceRef.current?.destroy();

            // eslint-disable-next-line react-hooks/exhaustive-deps
            const loginBtn: any = nullTokenRef?.current?.querySelector('#login-btn');
            loginBtn?.removeEventListener('click');

            if (normalRef.aTimer) {
                clearInterval(normalRef.aTimer);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.wrapper}>
            {userInfo?.token ? (
                <div ref={containerRef} className={styles.wlWraper} id="wl-wraper" />
            ) : (
                <div ref={nullTokenRef} className={c(styles.wlWraper, styles.loginOut)} />
            )}
        </div>
    );
};

export default Waline;
