export const codeList = [
    {
        name: '联系我们',
        mts: 'Header.ContactUs',
        icon: 'icon-a-Slice4',
        link: '/contactUS',
    },
    {
        name: 'App',
        icon: 'icon-connect1',
        link: '/app',
    },
];
